import React, { useState, useEffect } from 'react';
import { Card, Form } from '@themesberg/react-bootstrap';
import { ref, remove, update, onValue, off } from 'firebase/database';
import { db } from '../firebaseConfig';

const MusicControl = () => {
  const [isEnabled, setIsEnabled] = useState(false);
  const [songRequests, setSongRequests] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Sistem durumu için realtime listener
    const systemRef = ref(db, 'Cafe/System/music');
    onValue(systemRef, (snapshot) => {
      if (snapshot.exists()) {
        setIsEnabled(snapshot.val().enabled);
      }
    });

    // Şarkı istekleri için realtime listener
    const requestsRef = ref(db, 'Cafe/SongRequests');
    onValue(requestsRef, (snapshot) => {
      if (snapshot.exists()) {
        const requests = [];
        snapshot.forEach((childSnapshot) => {
          requests.push({
            id: childSnapshot.key,
            ...childSnapshot.val()
          });
        });
        setSongRequests(requests.sort((a, b) => b.likes - a.likes)); // Beğeni sayısına göre sırala
      } else {
        setSongRequests([]);
      }
      setLoading(false);
    });

    // Cleanup function
    return () => {
      // Listener'ları temizle
      off(systemRef);
      off(requestsRef);
    };
  }, []);

  const toggleSystem = async () => {
    try {
      const systemRef = ref(db, 'Cafe/System/music');
      await update(systemRef, {
        enabled: !isEnabled
      });
    } catch (error) {
      console.error('Sistem durumu güncellenirken hata:', error);
    }
  };

  const deleteSong = async (songId) => {
    if (window.confirm('Bu şarkı isteğini silmek istediğinize emin misiniz?')) {
      try {
        const songRef = ref(db, `Cafe/SongRequests/${songId}`);
        await remove(songRef);
      } catch (error) {
        console.error('Şarkı silinirken hata:', error);
      }
    }
  };

  const deleteAllSongs = async () => {
    if (window.confirm('TÜM şarkı isteklerini silmek istediğinize emin misiniz?')) {
      try {
        const requestsRef = ref(db, 'Cafe/SongRequests');
        await remove(requestsRef);
      } catch (error) {
        console.error('Tüm şarkılar silinirken hata:', error);
      }
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <h4>Müzik İstek Sistemi Kontrolü</h4>
        </div>
      </div>

      <Card border="light" className="bg-white shadow-sm mb-4">
        <Card.Body>
          <div className="mb-4">
            <Form.Check 
              type="switch"
              id="music-system-switch"
              label={`Sistem ${isEnabled ? 'Açık' : 'Kapalı'}`}
              checked={isEnabled}
              onChange={toggleSystem}
            />
          </div>

          <div className="d-flex justify-content-between align-items-center mb-4">
            <h5 className="mb-0">Şarkı İstekleri ({songRequests.length})</h5>
            {songRequests.length > 0 && (
              <button 
                className="btn btn-danger btn-sm"
                onClick={deleteAllSongs}
              >
                Tümünü Sil
              </button>
            )}
          </div>

          {songRequests.map(request => (
            <Card key={request.id} className="mb-3">
              <Card.Body className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  <img 
                    src={request.albumCover} 
                    alt={request.name}
                    style={{ width: '50px', height: '50px', marginRight: '1rem' }}
                  />
                  <div>
                    <h6 className="mb-0">{request.name}</h6>
                    <small className="text-muted">{request.artist}</small>
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <span className="me-3">
                    👍 {request.likes || 0}
                    &nbsp;&nbsp;
                    👎 {request.dislikes || 0}
                  </span>
                  <button 
                    className="btn btn-danger btn-sm"
                    onClick={() => deleteSong(request.id)}
                  >
                    Sil
                  </button>
                </div>
              </Card.Body>
            </Card>
          ))}
        </Card.Body>
      </Card>
    </>
  );
};

export default MusicControl; 